<template>
    <div class="main-con">
        <div class="swipter-con">
            <div class="left-list">
                <div class="main-list" v-for="(item,index) in class_list" :key="index" @mouseleave="Mouseleave">
                    <div class="main-list-item" @mouseover="showSecondMenu(item,index)" :class="bat==index?'bgc':''">
                        {{item.pname}}
                    </div>
                </div>
                <div class="sec-list" v-show="moveflag" @mouseover="ChangeColor" @mouseleave="closeSec">
                    <div class="sec-list-item" v-for="(item1,index1) in childrens" :key="index1">
                        <div class="row-con" @click="tohomelist(item1.cid,item1.pname,ceid,pnames)">
                            <div class="divider"></div>
                            <div style="flex:1;">{{item1.pname}}</div>
                            <span>></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="middle" v-if="banner.length>0">
                <el-carousel :interval="5000" arrow="always" height="450px" width="750px">
                    <el-carousel-item v-for="item in banner" :key="item.id">
                        <div class="adb" :style="{backgroundImage:'url('+item.image+')'}"></div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="right-info">
                <div class="user-con">
                    <div class="user-info">
                        <div v-if="user_name">
                            <img :src="headimgurl"/>
                        </div>
                        <div v-else>
                            <img src="@/assets/image/aa_icon_touxiang.png" />
                        </div>
                        <div class="info-con" v-if="user_name">
                            <p class="e-title">你好，{{user_name}}</p>
                            <p class="s-title">欢迎来到爱色素</p>
                        </div>
                        <div class="info-con" v-else>
                            <p class="e-title">Hi～欢迎您！</p>
                            <p class="s-title">优质好产品，就在爱色素！</p>
                        </div>
                    </div>
                    <div class="btn-con">
                        <div class="btn" v-if="user_name">
                            我的账号
                        </div>
                        <div class="btn" v-else @click="tologin">
                            快捷登录/注册
                        </div>
                    </div>
                   <div class="bottom-con">
                       <div class="menu-con" @click="tocontract">
                           <img src="@/assets/image/aa_icon_lianxikefu@2x.png" />
                           <div>联系客服</div>
                       </div>
                       <div class="menu-con" style="margin-right:0px;" @click="toMessage">
                           <img src="@/assets/image/aa_icon_xiaoxiguanli@2x.png" />
                           <div>消息管理</div>
                       </div>
                       <div class="menu-con" @click="toCart">
                           <img src="@/assets/image/aa_icon_gouwuche@2x.png" />
                           <div>我的购物车</div>
                       </div>
                        <div class="menu-con" style="margin-right:0px;" @click="toMyorder">
                           <img src="@/assets/image/aa_icon_wodedingdan@2x.png" />
                           <div>我的订单</div>
                       </div>
                   </div>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>
import {ref,toRefs,onMounted,reactive, computed} from 'vue'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
export default {
    props:{
        banner: {
            type: Array,
            default: () => {

            }
        },
        class_list:{
            type:Array,
            default:()=>{

            }
        }
    },
    setup(props){
        let childrens = ref('')
        let bat = ref('')
        let moveflag = ref(false)
        const router = useRouter()
        const store = useStore()
        let ceid= ref('')
        let pnames = ref('')
        let pc_user = reactive({
            "access_id":"",
            "headimgurl":""
        })
        onMounted(()=>{
            pc_user.access_id = JSON.parse(localStorage.getItem("pc_user")).access_id
            pc_user.headimgurl = JSON.parse(localStorage.getItem("pc_user")).headimgurl
        })
        const user_name =computed(()=>{
            return store.state.user_name
        })
        const toMessage = () =>{
            router.push({
                path:'/profile/message'
            })
        }
        const tocontract = () =>{
            window.open('https://chatlink123.meiqia.cn/widget/standalone.html?eid=91e310ce28069b6d086aebc6ce04b69f')
        }
        const {class_list} = toRefs(props)
        const showSecondMenu =(item,index)=>{
            childrens.value =  class_list.value[index].children
            bat.value = index
            moveflag.value = true
            ceid.value = item.cid
            pnames.value = item.pname
        }
        const Mouseleave=()=>{
            
           if(!moveflag.value)
           {
               bat.value = 9999
           }
           moveflag.value =false
          
        }
        const ChangeColor=()=>{
            moveflag.value=true
        }
        const closeSec=()=>{
            moveflag.value = false
            bat.value =9999
        }

        const toCart =()=>{
            router.push({
                path:'/shopcart'
            })
        }
        const toMyorder = () =>{
            router.push({
                path:'/profile/order'
            })
        }
        const tohomelist = (pid,pname,cid,pnames)=>{
            router.push({path:"/homelist",query:{pid:pid,pname:pname,cid:cid,pnames:pnames}})
        }
        const tologin =() =>{
            router.push({path: '/login', query: {selected: 1}})
        }
        return{
            childrens,
            bat,
            moveflag,
            ceid,
            pnames,
            ...toRefs(pc_user),
            user_name,
            showSecondMenu,
            Mouseleave,
            ChangeColor,
            closeSec,
            tohomelist,
            tologin,
            toCart,
            tocontract,
            toMessage,
            toMyorder
        }
    }
}
</script>
<style scoped>
    .main-con{
        width: 100%;
        height: 450px;
        background-color: rgb(245, 247, 249);
    }
    .main-con .swipter-con{
        width: 1200px;
        margin: 0 auto;
        display: flex;
    }
    .left-list{
        width: 192px;
        position: relative;
        width: 192px;
        height: 450px;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }
    .left-list .main-list{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 42px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #FFFFFF;
        line-height: 42px;
    }
    .main-list-item{
        padding-left: 20px;
    }
    .sec-list{
        position: absolute;
        top: 0px;
        left: 192px;
        width: 210px;
        height: 450px;
        background-color:#fff;
        z-index: 100;
    }
    .sec-list-item{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 42px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000;
        line-height: 42px;
        padding-left: 20px;
    }
    .sec-list-item:hover{
        background: #777;
    }
    .bgc{
        background: #D4282F;
    }
    .row-con{
        display: flex;
        align-items: center;
    }
    .divider{
        display: block;
        width: 3px;
        height: 14px;
        background: #D4282F;
        margin-right: 20px;
    }
    .row-con span{
        margin-right: 20px;
    }
    .middle{
        width: 750px;
        height: 450px;
    }
    .adb{
	background-position: center;
    background-repeat: no-repeat;
    transition: transform 3s linear;
    transform: scale(1,1);
    background-size: cover;
	width: 750px;
	height: 450px;
    }
    /* .el-carousel__arrow {
        border-radius: 0;
        width: 34px;
        height: 66px;
        font-size: 28px;
        background-color: rgba(0,0,0,0.6);
    } */

    .right-info{
        width: 258px;
        height: 450px;
        background: #FFFFFF;
    }
    .user-con{
        padding:40px 15px 30px 30px;
    }
    .user-info{
        display: flex;
        align-items: center;
    }
    .info-con{
        margin-left: 10px;
    }
    .user-info img{
        width: 55px;
        height: 55px;
        display: block;
    }
    .user-info .e-title{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
        margin-bottom: 4px;
    }
    .user-info .s-title{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
    }
    .btn-con{
        margin-top: 30px;
    }
    .btn-con .btn{
        width: 198px;
        height: 44px;
        background: #DA251C;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 44px;
        text-align: center;
        cursor: pointer;
    }
    .bottom-con{
        display: flex;
        flex-wrap: wrap;
        margin-top: 37px;
    }
    .menu-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70px;
        margin-right: 47px;
        margin-bottom: 42px;
        cursor: pointer;
    }
    .bottom-con img{
        width: 46px;
        height: 46px;
    }
    .menu-con div{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }

    
</style>