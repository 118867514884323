<template>
    <div class="con">
        <div class="sec-con">
            <div class="top-con">
                <div class="title-con">
                    <h3 class="c-title">热销推荐</h3>
                    <p class="e-title">Hot Products</p>
                </div>
            </div>
            <div class="img-con">
                <img src="@/assets/image/rexiaotuijian.jpg" />
            </div>
            <div class="commodity-con">
                <div class="item" v-for="item in list_r" :key="item.id" @click="todetail(item.id)">
                    <div style="background:#fff;">
                        <img :src="item.imgurl" class="img"/>
                    </div>
                    
                    <div class="bottom-con">
                        <div class="commodity-title">{{item.product_title}}</div>
                        <p>¥{{item.price}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="sec-con">
            <div class="top-con">
                <div class="title-con">
                    <h3 class="c-title">新品热卖</h3>
                    <p class="e-title">New Products</p>
                </div>
            </div>
            <div class="img-con">
                <img src="@/assets/image/xinpinrenmai.jpg" />
            </div>
            <div class="commodity-con">
                <div class="item" v-for="item in list_x" :key="item.id" @click="todetail(item.id)">
                    <div style="background:#fff;">
                        <img :src="item.imgurl" class="img"/>
                    </div>
                    
                    <div class="bottom-con">
                        <div class="commodity-title">{{item.product_title}}</div>
                        <p>¥{{item.price}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {useRouter} from 'vue-router'
export default {
    props:{
        list_r:{
            type:Array,
            default:()=>{

            }
        },
        list_x:{
            type:Array,
            default:()=>{

            }
        }
    },
    setup(){
        const router = useRouter()
        const todetail = (id)=>{
            const { href } = router.resolve({
                path: '/homedetail',
                query: {
                     good_id:id
                }
            });
            window.open(href, "_blank");
        }
        return {
            todetail
        }
    }
}
</script>
<style scoped>
    .con{
        width: 100%;
        background: #F5F7F9;
        margin-top: 50px;
    }
    .sec-con{
        width: 1200px;
        margin: 0 auto;
    }
    .top-con{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
    }
    .title-con .c-title{
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #d00e17;
        font-size: 22px;
        line-height: 30px;
    }
    .title-con .e-title{
        font-size: 12px;
        line-height: 10px;
        color: #999999;
    }
    .arrow-con{
        display: flex;
        align-items: center;
    }
    .arrow-con .label{
        font-size: 14px;
        color: #666666;
        cursor: pointer;
    }
    .arrow-con .arrow{
        width: 15px;
        height: 15px;
        border: 2px solid #C4C4C4;
        border-radius: 50%;
        line-height: 12px;
        text-align: center;
        margin-left: 10px;
        margin-top: 2px;
        color: #ccc;
    }
    .img-con{
        margin-top: 30px;
    }
    .commodity-con{
        width: 1200px;
        margin: 30px auto 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .item{
        width: 288px;
        height: 390px;
        margin-right: 16px;
        margin-bottom: 15px;
        background: #fff;
    }
    .item .img{
        padding: 32px;
    }
    .item:nth-child(4n){
        margin-right: 0px;
    }
    .commodity-title{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 30px;
        font-size: 14px;
        color: #020202;
        max-width: 80%;
        margin-bottom: 6px;
    }
    .bottom-con{
        margin-top: 20px;
        text-align: center;
    }
    .item:hover{
        box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2);
        background-color: #eeeeee;
    }
    .item p{
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 500;
        color: #D4282F;
        text-align: center;
    }
</style>