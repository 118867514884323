<template>
    <div class="brand-con">
        <div class="brand">
            <div class="top-con">
                <div class="title-con">
                    <div class="c-title">品牌特供</div>
                    <div class="e-title">Brand Supplier</div>
                </div>
                <div class="arrow-con">
                    <img src="@/assets/image/aa_icon_left@2x.png" style="margin-right:20px" @click="tolast"/>
                    <img src="@/assets/image/aa_icon_right@2x.png" @click="tonext"/>
                </div>
            </div>
            <div class="brand-list">
                <div class="brand-list-item" v-for="item in brand_list.slice(index,index+6)" :key="item.brand_id" @click="toBrand(item.brand_id,item.brand_name)">
                    <img :src="item.brand_pic"  class="img"/>
                    <p>{{item.brand_name}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {onMounted,reactive,toRefs,ref} from 'vue'
import {useRouter} from 'vue-router'
export default {
    props:{
        brand_list: {
            type: Array,
            default: () => {

            }
        }
    },
    setup(props){
        let pc_user = reactive({
            "access_id":"",
        })
        const router = useRouter()
        let list = ref('')
        let index =ref(0)
        const {brand_list} = toRefs(props)
        onMounted(()=>{
            pc_user.access_id = JSON.parse(localStorage.getItem("pc_user")).access_id
            list.value = brand_list.value.slice(index.value,index.value+6)
        })
        const tolast = ()=>{
            if(index.value>0){
                index.value -=6
                list.value = brand_list.value.slice(index.value,index.value+6)
            }
            
        }
        const toBrand = (id,name)=>{
            router.push({path:"/homelist",query:{pid:id,pname:name,brand:true}})
        }
        const tonext = ()=>{
            if(list.value.length ==0){
                index.value +=6
            }
            else if(list.value.length ==6){
                index.value +=6
            }
            else{
                return
            }
            list.value = brand_list.value.slice(index.value,index.value+6)
        }
        return {
            list,
            index,
            tolast,
            tonext,
            toBrand
        }
    }
}
</script>
<style scoped lang="scss">
    .brand-con{
        width: 100%;
        .brand{
            width: 1200px;
            margin: 30px auto 0;
            .top-con{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .title-con{
                    .c-title{
                        font-size: 22px;
                        color: #d00e17;
                        font-weight: 600;
                    }
                    .e-title{
                        font-size: 12px;
                        color: #ccc;
                    }
                }
                .arrow-con{
                    img{
                        width: 22px;
                        height: 22px;
                    }
                }
            }
            .brand-list{
                width: 1200px;
                margin-top: 20px;
                display: flex;
                .brand-list-item{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #ccc;
                    p{
                        width: 198px;
                        height: 20px;
                        line-height: 20px;
                        background-color: #E5E5E5;
                        text-align: center;
                        font-size: 12px;
                    }
                    .img{
                        height: 82px;
                    }
                }
            }
        }
    }
</style>