<template>
    <div class="con">
        <div v-for="(item,index) in product_list" :key="index">
            <div class="sec-con">
                <div class="top-con">
                    <div class="title-con">
                        <h3 class="c-title">{{item.pname}}</h3>
                        <p class="e-title">New Products</p>
                    </div>
                    <div class="arrow-con" @click="toMore(item)">
                        <div class="label">更多产品</div>
                        <div class="arrow">></div>
                    </div>
                </div>
                
                <div class="img-con">
                    <img :src="class_list[index].banner" />
                </div>

                <div class="carousel-con">
                    <el-carousel :interval="5000" height="646px" width="350px" v-if="Array.isArray(class_list[index].banner_list)">
                        <el-carousel-item v-for="(item2,index2) in class_list[index].banner_list" :key="index2">
                            <img :src="item2" class="carousel-img"/>
                        </el-carousel-item>
                    </el-carousel>
                    <div v-else>
                        <img :src="class_list[index].banner_list" class="carousel-img" />
                    </div>
                    <div class="commodity-con">
                        <template v-for="(item1,index1) in item.list" :key="index1" >
                            <div class="item" @click="todetail(item1.id)">
                                <div style="background:#fff;">
                                    <img :src="item1.imgurl" class="img"/>
                                </div>
                                
                                <div class="bottom-con">
                                    <div class="commodity-title">{{item1.product_title}}</div>
                                    <p>¥{{item1.price}}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import {onMounted,ref} from 'vue'
import {useRouter} from 'vue-router'
import {_post1} from '@/utils/req'
export default {
    props:{
        class_list:{
            type:Array,
            default:()=>{

            }
        }
    },
    setup(){
        let product_list = ref([])
        const router = useRouter()
        onMounted(()=>{
            setTimeout(() => {
                const class_list = JSON.parse(localStorage.getItem("class_list"))
                class_list.forEach(item=>{
                    const data= {
                        "module": "app_pc",
                        "action": "index",
                        "m": "search",
                        "cid": item.cid
                    }
                    _post1(data).then(res=>{
                        product_list.value.push({
                            "list":res.data.list.slice(0,8),
                            "pname":item.pname,
                            "cid":item.cid
                        })
                    })
                })
            }, 1000);
           
        })
        const todetail = (id)=>{
            console.log(1)
            const { href } = router.resolve({
                path: '/homedetail',
                query: {
                    good_id:id
                }
            });
            window.open(href, "_blank");
        }
        const toMore =(item) =>{
            router.push({path:"/homelist",query:{cid:item.cid,pname:item.pname,brand:true}})
        }
        return {
            product_list,
            todetail,
            toMore
        }
    }
}
</script>
<style scoped lang="scss">
    .con{
        width: 100%;
        background: #F5F7F9;
        .sec-con{
            width: 1200px;
            margin: 0 auto;
            .top-con{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 20px;
                .title-con{
                    .c-title{
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 600;
                        color: #d00e17;
                        font-size: 22px;
                        line-height: 30px;
                    }
                    .e-title{
                        font-size: 12px;
                        line-height: 10px;
                        color: #999999;
                    }
                }
                .arrow-con{
                    display: flex;
                    align-items: center;
                    .label{
                        font-size: 14px;
                        color: #666666;
                        cursor: pointer;
                    }
                    .arrow{
                        width: 15px;
                        height: 15px;
                        border: 2px solid #C4C4C4;
                        border-radius: 50%;
                        line-height: 12px;
                        text-align: center;
                        margin-left: 10px;
                        margin-top: 2px;
                        color: #ccc;
                    }
                } 
            }
            .img-con{
                margin-top: 30px;
            }
        }
        .carousel-con{
            display: flex;
            align-items: center;
            width: 1200px;
            margin: 30px auto 0;
            gap:30px;
            .carousel-img{
                height: 100%;
            }
            .commodity-con{
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap:16px;
                .item{
                    width: 204px;
                    height: 303px;
                    margin-bottom: 15px;
                    background: #fff;
                    .img{
                        padding: 32px;
                    }
                    .bottom-con{
                        margin-top: 20px;
                        text-align: center;
                        user-select:none;
                        .commodity-title{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin: 0 30px;
                            font-size: 14px;
                            color: #020202;
                            max-width: 80%;
                            margin-bottom: 6px;
                        }
                    }
                    p{
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 500;
                        color: #D4282F;
                        text-align: center;
                    }
                }
                .item:hover{
                    box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2);
                    background-color: #eeeeee;
                }
            }
        }
        
    }
    .con:last-child{
        padding-bottom: 100px;
    }
    ::v-deep .el-carousel{
        width: 300px;
    }
    ::v-deep .el-carousel__arrow{
        display: none;
    }
</style>