<template>
    <div class="home">
        <div class="banner">
            <ul class="banner-list">
                <li><a>产品分类</a></li>
                <li @click="tohome"><a>商场首页</a></li>
                <li><a>品牌合作</a></li>
                <li><a>合作供应商</a></li>
                <li @click="toContact('联系我们')"><a>联系我们</a></li>
                <li @click="toAboutus('关于我们')"><a>关于我们</a></li>
            </ul>
        </div>
        <Swipter :banner="banner" :class_list="class_list"></Swipter>
        <div class="banner-img">
            <img src="@/assets/image/aa_pic_youhuiquan@2x.png" class="bg-img"/>
        </div>
        <Brand :brand_list="brand_list"></Brand>
        <Hotnew :list_r="list_r" :list_x="list_x"></Hotnew>
        <Product :class_list="class_list"></Product>
    </div>
</template>
<script>
import Swipter from './child/Swipter.vue'
import Brand from './child/Brand.vue'
import Hotnew from './child/Hotnew.vue'
import Product from './child/Product.vue'
import { getData } from '@/utils/req'
import { onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
export default {
    setup() {
        const router = useRouter()
        let pc_user = reactive({
            "access_id":"",
        })
        let state = reactive({
            banner:[],
            brand_list:[],
            class_list:[],
            is_sign_status:'',
            list_r:[],
            list_x:[]
        })
        onMounted(()=>{
            pc_user.access_id = JSON.parse(localStorage.getItem("pc_user")).access_id
            axios()
        })
        const tohome =()=>{
            location.href = '/'
        }
        const axios =()=>{
            const data = {
                "module": "app_pc",
                "action": "index",
                "m": "home",
                "access_id": pc_user.access_id,
                "cid": ''
            }
            getData(data).then(res=>{
                state.banner = res.data.banner
                state.brand_list = res.data.brand_list
                state.class_list = res.data.class_list
                state.is_sign_status = res.data.is_sign_status
                state.list_r = res.data.list_r
                state.list_x = res.data.list_x
            })
        }
        const toContact =(info)=>{
            router.push({
                path:'/serve/contractus',
                query:{
                    info:info
                }
            })
        }
        const toAboutus =(info) =>{
             router.push({
                path:'/serve/aboutus',
                query:{
                    info:info
                }
            })
        }
        return{
            tohome,
            toContact,
            toAboutus,
            ...toRefs(state)
        }
    },
    components:{
        Swipter,
        Brand,
        Hotnew,
        Product
    }
}
</script>
<style scoped lang="scss">
    .home{
        width: 100%;
        margin-top: 20px;
        .banner{
            width:100%;
            height: 47px;
            background: #1F1A17;
            .banner-list{
                display: flex;
                align-items:center;
                width: 1200px;
                margin: 0 auto;
                li{
                    width: 170px;
                    height: 47px;
                    font-size: 18px;
                    font-family: PingFangTC-Medium, PingFangTC;
                    font-weight: 500;
                    color: #EBEBEB;
                    line-height: 47px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
        .banner-img{
            width: 1200px;
            margin: 50px auto 0;
            position: relative;
            .btn-img{
                position: absolute;
                top: 10px;
                right: 120px;
                width: 136px;
                height: 143px;
                z-index: 9;
                cursor: pointer;
            }
        }
    }
</style>